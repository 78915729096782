// Recidiviz - a data platform for criminal justice reform
// Copyright (C) 2025 Recidiviz, Inc.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU General Public License as published by
// the Free Software Foundation, either version 3 of the License, or
// (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.
// =============================================================================

import { observer } from "mobx-react-lite";

import { withPresenterManager } from "~hydration-utils";

import { useRootStore } from "../../components/StoreProvider";
import { ModelHydratorWithoutLoader } from "../../InsightsStore/hydrators/ModelHydratorWithoutLoader";
import { SupervisionSupervisorOutcomesPresenter } from "../../InsightsStore/presenters/SupervisionSupervisorOutcomesPresenter";
import InsightsActionStrategyBanner from "../InsightsActionStrategyBanner";

const usePresenter = () => {
  const {
    insightsStore: { supervisionStore },
  } = useRootStore();

  return supervisionStore?.supervisorPseudoId
    ? new SupervisionSupervisorOutcomesPresenter(
        supervisionStore,
        supervisionStore?.supervisorPseudoId,
      )
    : null;
};

const ManagedComponent: React.FC<{
  presenter: SupervisionSupervisorOutcomesPresenter;
}> = observer(function ActionStrategyBanner({ presenter }) {
  return presenter.actionStrategyCopy ? (
    <InsightsActionStrategyBanner
      actionStrategy={presenter.actionStrategyCopy}
      bannerViewedCallback={presenter.setUserHasSeenActionStrategy}
      disableBannerCallback={presenter.disableSurfaceActionStrategies}
    />
  ) : null;
});

export const InsightsSupervisorActionStrategyBanner = withPresenterManager({
  managerIsObserver: true,
  HydratorComponent: ModelHydratorWithoutLoader,
  usePresenter,
  ManagedComponent,
});
