// Recidiviz - a data platform for criminal justice reform
// Copyright (C) 2025 Recidiviz, Inc.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU General Public License as published by
// the Free Software Foundation, either version 3 of the License, or
// (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.
// =============================================================================

import { formatListWithAnd } from "../../../../../../../src/utils/utils";
import * as CommonStyled from "../../components/Styles";

export const RecidivismChartFootnote: React.FC<{
  missingSeriesLabels?: string[];
  isReport?: boolean;
}> = ({ missingSeriesLabels, isReport }) => {
  if (!missingSeriesLabels || !missingSeriesLabels.length) return null;

  return (
    <CommonStyled.ChartFootnote isReport={isReport}>
      *Note: There is insufficient data for{" "}
      {formatListWithAnd(missingSeriesLabels, "")} and they are not represented
      in the chart.
    </CommonStyled.ChartFootnote>
  );
};
